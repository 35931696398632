require('./kendo.core.js');
require('./kendo.fx.js');
require('./kendo.data.odata.js');
require('./kendo.data.xml.js');
require('./kendo.data.js');
require('./kendo.data.signalr.js');
require('./kendo.binder.js');
require('./kendo.validator.js');
require('./kendo.router.js');
require('./kendo.view.js');
require('./kendo.userevents.js');
require('./kendo.draganddrop.js');
require('./kendo.popup.js');
require('./kendo.touch.js');
require('./kendo.mobile.popover.js');
require('./kendo.mobile.loader.js');
require('./kendo.mobile.scroller.js');
require('./kendo.mobile.shim.js');
require('./kendo.mobile.view.js');
require('./kendo.mobile.modalview.js');
require('./kendo.mobile.drawer.js');
require('./kendo.mobile.splitview.js');
require('./kendo.mobile.pane.js');
require('./kendo.mobile.application.js');
require('./kendo.mobile.actionsheet.js');
require('./kendo.mobile.button.js');
require('./kendo.mobile.buttongroup.js');
require('./kendo.mobile.collapsible.js');
require('./kendo.mobile.listview.js');
require('./kendo.mobile.navbar.js');
require('./kendo.mobile.scrollview.js');
require('./kendo.mobile.switch.js');
require('./kendo.mobile.tabstrip.js');
require('./kendo.angular.js');

"bundle all";
