require('./kendo.core.js');
require('./kendo.router.js');
require('./kendo.view.js');
require('./kendo.fx.js');
require('./kendo.dom.js');
require('./kendo.data.odata.js');
require('./kendo.data.xml.js');
require('./kendo.data.js');
require('./kendo.ooxml.js');
require('./kendo.excel.js');
require('./kendo.data.signalr.js');
require('./kendo.binder.js');
require('./kendo.drawing.js');
require('./kendo.validator.js');
require('./kendo.userevents.js');
require('./kendo.draganddrop.js');
require('./kendo.mobile.scroller.js');
require('./kendo.groupable.js');
require('./kendo.reorderable.js');
require('./kendo.resizable.js');
require('./kendo.sortable.js');
require('./kendo.selectable.js');
require('./kendo.chat.js');
require('./kendo.button.js');
require('./kendo.buttongroup.js');
require('./kendo.breadcrumb.js');
require('./kendo.switch.js');
require('./kendo.pager.js');
require('./kendo.popup.js');
require('./kendo.notification.js');
require('./kendo.tooltip.js');
require('./kendo.list.js');
require('./kendo.calendar.js');
require('./kendo.datepicker.js');
require('./kendo.dateinput.js');
require('./kendo.drawer.js');
require('./kendo.multiviewcalendar.js');
require('./kendo.autocomplete.js');
require('./kendo.dropdownlist.js');
require('./kendo.dropdowntree.js');
require('./kendo.combobox.js');
require('./kendo.multiselect.js');
require('./kendo.multicolumncombobox.js');
require('./kendo.colorpicker.js');
require('./kendo.columnmenu.js');
require('./kendo.columnsorter.js');
require('./kendo.grid.js');
require('./kendo.listview.js');
require('./kendo.listbox.js');
require('./kendo.loader.js');
require('./kendo.filebrowser.js');
require('./kendo.imagebrowser.js');
require('./kendo.editor.js');
require('./kendo.numerictextbox.js');
require('./kendo.maskedtextbox.js');
require('./kendo.mediaplayer.js');
require('./kendo.menu.js');
require('./kendo.editable.js');
require('./kendo.pivot.fieldmenu.js');
require('./kendo.filter.js');
require('./kendo.filtercell.js');
require('./kendo.panelbar.js');
require('./kendo.progressbar.js');
require('./kendo.responsivepanel.js');
require('./kendo.tabstrip.js');
require('./kendo.timepicker.js');
require('./kendo.splitbutton.js');
require('./kendo.dropdownbutton.js');
require('./kendo.toolbar.js');
require('./kendo.datetimepicker.js');
require('./kendo.daterangepicker.js');
require('./kendo.treeview.draganddrop.js');
require('./kendo.treeview.js');
require('./kendo.scrollview.js');
require('./kendo.slider.js');
require('./kendo.splitter.js');
require('./kendo.upload.js');
require('./kendo.dialog.js');
require('./kendo.window.js');
require('./kendo.virtuallist.js');
require('./kendo.scheduler.view.js');
require('./kendo.scheduler.dayview.js');
require('./kendo.scheduler.agendaview.js');
require('./kendo.scheduler.monthview.js');
require('./kendo.scheduler.yearview.js');
require('./kendo.scheduler.recurrence.js');
require('./kendo.scheduler.js');
require('./kendo.gantt.data.js');
require('./kendo.gantt.editors.js');
require('./kendo.gantt.list.js');
require('./kendo.gantt.timeline.js');
require('./kendo.gantt.js');
require('./kendo.timeline.js');
require('./kendo.treelist.js');
require('./kendo.pivotgrid.js');
require('./kendo.spreadsheet.js');
require('./kendo.pivot.configurator.js');
require('./kendo.ripple.js');
require('./kendo.pdfviewer.js');
require('./kendo.rating.js');
require('./kendo.angular.js');
require('./kendo.badge.js');
require('./kendo.filemanager.js');
require('./kendo.stepper.js');
require('./kendo.textarea.js');
require('./kendo.textbox.js');
require('./kendo.form.js');
require('./kendo.floatinglabel.js');
require('./kendo.tilelayout.js');
require('./kendo.wizard.js');
require('./kendo.appbar.js');
require('./kendo.imageeditor.js');
require('./kendo.expansionpanel.js');
require('./kendo.floatingactionbutton.js');
require('./kendo.inputgroupbase.js');
require('./kendo.radiogroup.js');
require('./kendo.checkboxgroup.js');
require('./kendo.bottomnavigation.js');
require('./kendo.actionsheet.js');
require('./kendo.skeletoncontainer.js');
require('./kendo.taskboard.js');
require('./kendo.captcha.js');
require('./kendo.orgchart.js');
require('./kendo.popover.js');
require('./kendo.toggleinputbase.js');
require('./kendo.checkbox.js');
require('./kendo.radiobutton.js');
require('./kendo.avatar.js');
require('./kendo.circularprogressbar.js');
require('./kendo.signature.js');
require('./kendo.timeselector.js');
require('./kendo.timedurationpicker.js');
require('./kendo.chip.js');
require('./kendo.chiplist.js');
require('./kendo.togglebutton.js');
require('./kendo.icons.js');

"bundle all";
